<template>
  <div class="menu">

    <nav>
      <a href="/">
        <p>Mygirlweb</p>
      </a>
      
      <img src="../assets/iconos/menu.png" alt="" v-on:click="openMenu">

    </nav>
    
  </div>
</template>

<script>
  export default {
    created() 
    {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() 
    {
      window.removeEventListener("scroll", this.handleScroll);
    },
    data: function () {
      return {
        
      };
    },
    methods: 
    {
      openMenu: function()
      {
        this.$store.state.menuOpc = true;
        console.log("si");
      } 
           
    },
    computed: {},
    mounted: function () {
      
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    
    text-decoration: none;
  }
  

  nav 
  {
    position: fixed;
    width: 95%;
    height: 88px;
    left: 2.5%;
    top: 0;
        
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    margin-bottom: 30px;
    
  }

  nav p 
  {
    font-family: monB;
    font-size: 28px;
    color: #DDAAAA;
    letter-spacing: 1;
    margin: 0;
    cursor: pointer;
  }

  nav img
  {
    width: 38px;
    height: 20px;
    cursor: pointer;
  }
  
   /*Tablets & larger smartphones*/
   @media (min-width:561px) and (max-width:850px)
  {
    nav p 
    {
      font-size: 35px;
    }
   
  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {
    nav p 
    {
      font-size: 45px;
    }

    nav img 
    {
      width: 44px;
      height: 20px;
    }

  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {
    nav 
    {
      width: 98%;
      left: 1%;
    }

    nav p 
    {
      font-size: 40px;
    }

    nav img 
    {
      width: 43px;
      height: 18px;
    }

  }

  
</style>