import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuOpc: false,
    //urlApi: "http://localhost:4000",
    urlApi: "https://adkapi.tech",

    amount: '30',
    currency: 'MXN',
    enviroment: 'production', //production - sandbox
    sandbox: 'AeAphc9PjL6s66g1NJsfW0ADzO8XmiZhiwbab_mcPQ0WT5i7Qvj3g6fsNkCxLah0q2JhCbV2SQmLm49p',
    production: 'AZXEZpD5NfOk3jh6569vj3Ii65pGsXMHSUbSubmunQ0oNrERnkXw_g6rPYgkt79bASCo2eC6ERWT3M5S'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
