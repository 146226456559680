<template>
  <div class="detalle">

    <!-- <nav>
      <p>Mygirlweb</p>
      <img src="../assets/iconos/menu.png" alt="">

    </nav> -->

    <div class="mainInner"  v-on:scroll="handleScroll" v-for="item in articulo">
    
      <p class="title">{{ item.titulo }}</p>
      <p class="subtitle">{{ item.subtitulo }}</p>

      <div class="row" id="home">
        <div class="articulo">
          
          <img :src="'https://adkapi.tech/art/' + item.carpeta + '/' + item.img_portada" alt="">
          <p>{{ item.parrafo_uno }}</p>

          <img :src="'https://adkapi.tech/art/' + item.carpeta + '/' + item.img_uno" alt="">
          <p>{{ item.parrafo_dos }}</p>

          <img :src="'https://adkapi.tech/art/' + item.carpeta + '/' + item.img_dos" alt="">
          <p>{{ item.parrafo_tres }}</p>

          <img :src="'https://adkapi.tech/art/' + item.carpeta + '/' + item.img_tres" alt="">
          <p>{{ item.conclusion }}</p>

          <br>
          <p>Autor: {{ item.nombre }}</p>

        </div>

      </div>
            
      
    </div>

    
  </div>
</template>

<script>
  export default {
    created() 
    {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() 
    {
      window.removeEventListener("scroll", this.handleScroll);
    },
    data: function () {
      return {
        urlApi: this.$store.state.urlApi,
        articulo: []        
      };
    },
    methods: 
    {
      obtenerDetalle: function(id)
      {
        var thisTemporal = this;

        fetch(thisTemporal.urlApi + '/postBlog?idpost='+ id)
        .then(function(response)
        {          
          response.json()
          .then(function(response)
          {
            for (var key in response.respuesta) 
            {
       
              thisTemporal.articulo.push(response.respuesta[key]);
            }            
          });
        })
        .catch(err =>
        {
          console.log(err);
        });

        

      },
      handleScroll: function () {},
      
      scrollHome: function () 
      {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });

      },

      
    },
    computed: {},
    mounted: function () {
      this.scrollHome();

      let id_recuperado = this.$route.params.id;
      this.obtenerDetalle(id_recuperado);
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    text-decoration-color: #707070 !important;
  }
  
  .detalle
  {  
    overflow: auto;
    scroll-snap-type: y mandatory;
    background-image: url("../assets/iconos/textura2.png"), url("../assets/iconos/textura3.png");
    background-repeat: no-repeat, no-repeat;
    background-position: 90% 30%, bottom left;
    background-attachment: fixed;
  }

  
  .mainInner
  {
    width: 100%;
    height: auto;
    margin-top: 115px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .title
  {
    font-size: 24px;
    font-family: monB;
    margin: 0;
    color: #3A3A3A;
    text-align: left;
  }

  .subtitle
  {
    font-size: 20px;
    font-family: monB;
    color: #3A3A3A; 
    text-align: left;   
  }

  .row 
  {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .articulo
  {
    width: 100%;
    margin-bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .articulo img 
  {
    width: 100%;
    height: auto;
    border: 2px dotted #DDAAAA;
    box-sizing: border-box;
  }

  .articulo p 
  {
    color: #3A3A3A;
    font-family: monR;
    font-size: 24px;
    text-align: left;
    letter-spacing: 1px;
    line-height: 1.6;
    
  }

  footer
  {
    width: 100%;
    height: 180px;
    margin-top: 30px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .redes
  {
    width: 25%;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .redes img 
  {
    width: 25px;
    height: 25px;
  }

  footer p 
  {
    margin: 0;
    color: #707070;
  }

  /*Tablets & larger smartphones*/
  @media (min-width:561px) and (max-width:850px)
  {
    .title
    {
      font-size: 24px;
    }

    .subtitle 
    {
      font-size: 18px;
    }

    .articulo p 
    {
      font-size: 18px;
    }
    
  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {
    .detalle
    {
      background-position: 90% 10%, 10% 80%;
    }

    .title
    {
      font-size: 29px;
    }

    .subtitle 
    {
      font-size: 20px;
    }

    .articulo p 
    {
      font-size: 20px;
    }
    
  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {
    .detalle
    {
      background-position: 90% 10%, 10% 80%;
    }

    .title
    {
      font-size: 35px;
      
    }

    .subtitle 
    {
      font-size: 28px;
    }

    .articulo p 
    {
      font-size: 28px;
    }

    .mainInner 
    {
      width: 75%;
    }

  }



  
</style>