<template>
  <div class="footer">    

      <!--FOOTER-->

      <footer>

        <div class="redes">
          <a href="https://www.instagram.com/mygirlweb/" target="_blank">
            <img src="../assets/iconos/instagram.png" alt="instagram">
          </a>

          <a href="https://www.facebook.com/Mygirlweb-932190477126404" target="_blank">
            <img src="../assets/iconos/facebook.png" alt="">
          </a>

          <img src="../assets/iconos/paypalIcono2.png" alt="" v-on:click="abrirPaypal">

        </div>

        <a href="/contacto"><p>Contacto</p></a>
        
        <p>Hecho con &hearts; por <a href="https://adhok.mx/" target="_blank">Adhok</a> </p>
        <p>2022 © // All rights reserved</p>

      </footer>


      <div id="paypalCont">
        <div class="paypalInner">
          <img class="logoPP" src="../assets/paypal2.png" alt="">
          <p>Recuerda que puedes hacer donaciones utilizando Paypal. Nos ayuda mucho para seguir ofreciéndote artículos y herramientas que te ayuden en tu día a día, estaremos infinitamente agradecidos.</p>
  
          <input type="radio" name="pesos" value="30" checked v-on:click="monto" checked><label class="num">$30<i>MXN</i></label>
          <input type="radio" name="pesos" value="50" v-on:click="monto"><label class="num">$50<i>MXN</i></label>
          <input type="radio" name="pesos" value="100" v-on:click="monto"><label class="num">$100<i>MXN</i></label>
  
          <!--<button type="button" name="button" id="btnPP">Paypal</button>-->
          <PayPal
          :amount="amount"
          :currency= "$store.state.currency"
          :client="paypal"
          :env= $store.state.enviroment
          :button-style="myStyle"
          @payment-authorized="payment_authorized_cb"
          @payment-completed="payment_completed_cb"
          @payment-cancelled="payment_cancelled_cb">
          </PayPal>
  
          <button type="button" id="btnCerrarPP" v-on:click="cerrarPP"></button>
        </div>
    </div>
      
      
    </div>
    
  </div>
</template>

<script>
  import PayPal from 'vue-paypal-checkout'
  export default {
    
    data: function () {
      return {

        paypal: {
          sandbox: this.$store.state.sandbox,
          production: this.$store.state.production
        },
        myStyle:
        {
          label: 'paypal',
          size:  'small',
          shape: 'rect',
          color: 'gold',
          tagline: 'false'
        },
        amount: this.$store.state.amount,
        
      };
    },
    components: { PayPal },
    methods: 
    {

      abrirPaypal: function(event)
      {
          document.getElementById('paypalCont').style.display = 'flex';
      },
      cerrarPP: function(event)
      {
          document.getElementById('paypalCont').style.display = 'none';
      },
      monto: function(event)
      {
        this.amount = event.target.value;
        console.log(this.amount);
      },
      payment_authorized_cb: function(res)
      {
        //console.log(res);
      },
      payment_completed_cb: function(res)
      {
        console.log(res.state);
        if (res.state == 'approved')
        {
          alert("¡Gracias por tu donación!");
          document.getElementById('paypalCont').style.display = 'none';
        }
        else
        {
            alert("El pago no se pudo realizar, intentalo mas tarde.")
            document.getElementById('paypalCont').style.display = 'none';
        }
      },
      payment_cancelled_cb: function(res)
      {
        console.log(res);
        alert("El pago no se pudo realizar, intentalo mas tarde.")
      },
      
           
    },
    computed: {},
    mounted: function () {
      
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    text-decoration-color: #707070 !important;
  }

    
  footer
  {
    width: 100%;
    height: 180px;
    margin-top: 30px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .redes
  {
    width: 25%;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .redes img 
  {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  footer p 
  {
    margin: 0;
    color: #707070;
    font-family: monR;
  }

  #paypalCont
{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10000;

}
.paypalInner
{
    position: relative;
    background: #fff;
    border-radius: 5px;

}
.paypalInner
{
    width: 90%;
    height: auto;
    padding: 52px 0 48px 0;
    box-shadow: 0 3px 6px #00000029;
    box-sizing: border-box;
}
.paypalInner .logoPP
{
    width: 20%;
    margin-bottom: 20px;
}
.paypalInner h2
{
    font-size: 15px;
    line-height: 29px;
    margin: 0;
    margin-bottom: 39px;
    font-family: monR;
}
.paypalInner p
{
    font-size: 12px;
    line-height: 22px;
    padding: 0 50px;
    font-family: monR;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 30px;
}
.paypalInner input
{
  margin: 0 3px 30px 10px;
}
.num
{
  font-family: monR;
  font-size: 14px;
  margin-right: 10px;
}

.num i
{
  font-size: 8px;
  font-family: monR;
}
.paypalInner #btnPP
{
    width: 73.35%;
    height: 35px;
    background: #F7BE38;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0 6px 10px #00000029;
    border: none;
    box-sizing: border-box;
}
#btnCerrarPP
{
    position: absolute;
    width: 18px;
    height: 18px;
    top: 15px;
    right: 22px;
    background: url("../assets/iconos/close2.png") no-repeat center;
    background-size: 100% auto;
    border: none;
    cursor: pointer;
}

   /*Tablets & larger smartphones*/
  @media (min-width:561px) and (max-width:850px)
  {
    .redes 
    {
      width: 15%;
    }

    footer p 
    {
      font-size: 16px;
    }

    .paypalInner
    {
      width: 50%;
    }

  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {
    .redes 
    {
      width: 10%;
    }

    footer p 
    {
      font-size: 16px;
    }

    .paypalInner
    {
      width: 50%;
    }
  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {
    .redes 
    {
      width: 5%;
    }

    footer p 
    {
      font-size: 14px;
    }

    .paypalInner
    {
      width: 40%;
    }
  }


  
</style>